import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { AssetType } from 'pages/course/[id]/activities/assignment/[assignment_id]'
import { UserType } from 'types/UserAdditionMetaData'

export async function getForum(forum_id: string, viewAs: string) {
	return await handleApi<
		AssetType & {
			parents: {
				data: string
				_id: string
				children: { createdAt: number; data: string; author: { first_name: string; last_name: string; teacher: boolean } }[]
			}[]
		}
	>(axios.get(getUrl(`forum/?uid=${forum_id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions()))
}
export async function getSubmissionForum(forum_id: string, course_id: string) {
	return await handleApi<(UserType & { isOpened: boolean; last_open_date: Date })[]>(
		axios.get(getUrl(`forum/get_forum_submission_list?forum_id=${forum_id}&course_id=${course_id}`), getDefaultOptions())
	)
}
