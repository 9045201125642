import { getDefaultOptions } from 'API/auth'
import { getForum } from 'API/getForum'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { navigate, PageProps } from 'gatsby'
import { useEditableHook } from 'hooks/useEditableHook'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { useGetModulesHook } from 'hooks/useGetModulesHook'
import React from 'react'
import { CustomModal } from '../../../../../components/common/useModal'
import { toast } from 'react-toastify'

const Resource: React.FC<PageProps> = ({ params: { id: course }, location }) => {
	let ref = React.useRef<HTMLButtonElement | null>(null)

	const { type_of_course, stable } = useGetCourseDetails(course)
	const { modules } = useGetModulesHook({ course, stable, type_of_course })

	const { edit, elem_id } = useEditableHook({ location })
	const [editable, setEditable] = React.useState<any>({})
	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getForum(elem_id, 'Teacher')
				if (asset) {
					console.log('asset', asset)
					setEditable(asset)
				}
			}
			run()
		}
	}, [edit, elem_id])
	return (
		<>
			<CustomModal
				header='Add Forum'
				onClick={async () => {
					if (edit === 'true') {
						if (ref.current) {
							ref.current.click()
						}
					}
					console.log(ref)
					if (ref.current) {
						ref.current.click()
					}
				}}
			>
				<Form
					editableState={editable}
					getRef={(_ref) => {
						if (_ref) ref = _ref
					}}
					action={async ({ state }) => {
						if (edit === 'true') {
							if (edit === 'true') {
								console.log(editable)
								const forum = await handleApi<{ uid: string }>(
									axios.patch(
										getUrl('forum/'),
										{
											...state,
											course,
										},
										getDefaultOptions()
									)
								)
								if (forum) navigate(`/course/${course}/activities/forum/${forum.uid}`)
							}
						} else {
							const forum = await handleApi<{ uid: string }>(
								axios.post(
									getUrl('forum/'),
									{
										...state,
										course,
									},
									getDefaultOptions()
								)
							)
							if (forum) navigate(`/course/${course}/activities/forum/${forum.uid}`)
						}
					}}
					text={'Add Forum'}
					fields={[
						{
							type: 'text',
							label: 'Title',
							name: 'title',
						},
						{
							type: 'textarea',
							label: 'Description',
							name: 'description',
						},
						modules.length > 0
							? {
									type: 'select',
									selection: modules,
									name: 'module',
									label: 'Module',
							  }
							: {
									type: 'select',
									selection: [],
									label: '',
									name: '',
							  },
					]}
				></Form>
			</CustomModal>
		</>
	)
}

export default Resource
